import { Directive, HostListener, Inject, Input, NgZone } from "@angular/core";
import { ActionAndContext } from "src/app/infrastructure/actionAndContext";
import { PlatformHelpersService } from "../services/platform.helpers.module/platform.helpers.service";
import { Router } from "@angular/router";

@Directive({
    selector: '[nav-anchor]',
    standalone: true,
})
export class NavAnchorDirective {
    @Input('new-tab') newTab: boolean;
    @Input('server-side') serverSide: boolean;
    @Input('execute-before-navigation') executeBeforeNavigation: ActionAndContext<void>;

    @HostListener('click', ['$event']) click(e) {
        e.preventDefault();
        if (this.executeBeforeNavigation) {
            this.executeBeforeNavigation.Execute();
        }
        var url: string = e.currentTarget.attributes['href'].value;
        if (url && (url.toLowerCase().startsWith("http://") || url.toLowerCase().startsWith("https://") || this.serverSide)) {
            if (this.newTab) {
                this.platformService.Window.open(url, '_blank');
            } else {
                this.platformService.Window.location.href = url;
            }
            return;
        }

        this.ngZone.run(() => {
            this.router.navigateByUrl(url).then(r => {
                if (!r) {
                    this.platformService.Window.location.href = url;
                }
            });
        });
    }

    constructor(
        private ngZone: NgZone,
        private router: Router,
        private platformService: PlatformHelpersService
    ) { }
}
